import React from 'react';
import Layout from 'components/layout/Layout';
import styles from './About.module.css';

function About() {
    return (
        <Layout>
            <div className={styles.about}>
                <h1 className={styles.title}>About</h1>
                <p>Hypevizz is a blog dedicated for creating rich interactive visualizations in hot/trending topics.</p>
                <p>Created by <a href="https://twitter.com/finnhvman">@finnhvman</a>.</p>
            </div>
        </Layout>
    );
}

export default About;
