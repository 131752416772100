import React from 'react';
import firestore from 'services/firebase/firestore';
import Layout from 'components/layout/Layout';
import PostCard from 'components/post/PostCard';
import TextProgress from 'components/progress/TextProgress';
import styles from './Home.module.css';

const examplePost = {
    id: 'id',
    title: 'Travis Scott\'s every collaboration',
    author: {
        id: 'finnhvman',
        name: 'Ben Szabo',
        avatar: 'https://css-tricks.com/wp-content/uploads/2017/12/finnhvman-350x350.jpg'
    },
    published: 2,
    // updated: 1576447512913,
    tags: ['music', 'rap', 'here', 'maybe', 'figure', 'out', 'different', 'examples', 'for', 'a', 'change'],
    cover: 'https://www.columnfivemedia.com/wp-content/uploads/2018/03/How-to-use-data-visualization-report-design.png',
    description: 'Look at this example: a three-quarter majority But another example shows: 75 a two-thirds majority Now I am in a puzzle about the matter.'
};

const fakePosts = Array(10).fill(examplePost, 0, 10);

fakePosts[0] = {
    id: 'teamtrees-donations',
    title: 'Team Trees Donations',
    author: {
        id: 'finnhvman',
        name: 'Ben Szabo',
        avatar: 'https://css-tricks.com/wp-content/uploads/2017/12/finnhvman-350x350.jpg'
    },
    published: 1577625051,
    updated: 1577625051,
    tags: ['YouTube', 'nature'],
    cover: 'https://www.sciencealert.com/images/2019-10/processed/team_trees_cover_600.jpg',
    description: 'Look at this example: a three-quarter majority But another example shows: 75 a two-thirds majority Now I am in a puzzle about the matter.'
};

function Home() {
    const [ progress, setProgress ] = React.useState(true);
    const [ error, setError ] = React.useState(false);
    const [ posts, setPosts ] = React.useState([]);
    React.useEffect(() => {
        firestore
            .collection('posts')
            .orderBy('published', 'desc')
            .get()
            .then((querySnapshot) => {
                const items = querySnapshot.docs.map((doc) => {
                    const data = doc.data();
                    return {
                        ...data,
                        published: data?.published?.seconds,
                        updated: data?.updated?.seconds,
                        id: doc.id
                    };
                });

                setProgress(false);
                setPosts(items);
            }, () => setError(true));
    }, []);

    const renderProgress = () => {
        return (
            <div className={styles.feedProgress}>
                <TextProgress>Fetching Posts...</TextProgress>
            </div>
        );
    };

    const renderError = () => {
        return (
            <div className={styles.feedError}>
                <div>
                    <span className={styles.emoji} aria-hidden="true" role="img">🤯</span><br/><br/>
                    Something wrong happened<br/><br/>
                    Try <a href={window.location.href}>refreshing</a> the page, it might make the problem go away
                </div>
            </div>
        );
    };

    const renderPosts = () => {
        return (
            <ul className={styles.feed}>
                {posts.map(post => <li key={post.id} className={styles.feedItem}><PostCard {...post}/></li>)}
                <li className={styles.feedEnd}>
                    <div>
                        <span className={styles.emoji} aria-hidden="true" role="img">😌</span><br/><br/>
                        You have reached the end of posts<br/><br/>
                        Tweet <a href="https://twitter.com/hypevizz">@hypevizz</a> your ideas if you would like to see more!
                    </div>
                </li>
            </ul>
        );
    };

    return (
        <Layout>
            {progress ? renderProgress() : error ? renderError() : renderPosts()}
        </Layout>
    );
}

export default Home;
