import React from 'react';
import styles from './Layout.module.css';

function Layout({ children }) {
    return (
        <div className={styles.layout}>
            <div className={styles.spacer}/>
            <main className={styles.main}>
                {children}
            </main>
            <aside className={styles.aside}>

            </aside>
        </div>
    );
}

export default Layout;
