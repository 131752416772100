const dateOnlyFormatter = new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
});

const dateTimeFormatter = new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
});

export const dateOnly = (seconds) => {
    return dateOnlyFormatter.format(seconds * 1000);
};

export const dateTime = (seconds) => {
    return dateTimeFormatter.format(seconds * 1000);
};

export const isoString = (seconds) => {
    return new Date(seconds * 1000).toISOString();
};
