import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import styles from './Error.module.css';

function NotFound() {
    const history = useHistory();
    return (
        <main className={styles.error}>
            <h1 className={styles.title}>404 Not Found</h1>
            <div className={styles.links}>
                <span className={styles.link} onClick={history.goBack}>Back</span>
                <Link className={styles.link} to="/">Home</Link>
            </div>
        </main>
    );
}

export default NotFound;
