import React from 'react';
import * as formatter from 'utils/dateTimeFormatter';
import styles from './PostCard.module.css';

function PostCard({ id, title, author, published, updated, image, description, tags }) {
    let selection = null;
    const handleMouseDown = () => selection = window.getSelection().toString();
    const handleMouseUp = () => selection === window.getSelection().toString() && window.location.assign(`/posts/${id}`);
    return (
        <article className={styles.article} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
            <header>
                <h2 className={styles.title}><a href={`/posts/${id}`}>{title}</a></h2>
                <div className={styles.authorLine}>
                    <a href={`/authors/${author.id}`} title={author.name} rel="author">
                        <address className={styles.author}>
                            <img className={styles.authorAvatar} src={`/authors/${author.id}-small.jpg`} alt=""/>{author.id}
                        </address>
                    </a> &middot; <time
                        className={styles.published}
                        dateTime={formatter.isoString(published)}
                        title={'published at ' + formatter.dateTime(published)}
                    >
                        {formatter.dateOnly(published)}
                    </time>{updated && <> &middot; <time
                        className={styles.updated}
                        dateTime={formatter.isoString(updated)}
                        title={'updated at ' + formatter.dateTime(updated)}
                    >
                        {formatter.dateOnly(updated)}{' '}
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="none" stroke="currentColor" d="M8 8v0 -4M8 8l2 2M8 2a6 6 0 1 0 0.1 0z"/></svg>
                    </time></>}
                </div>
            </header>
            <section className={styles.section}>
                <img className={styles.cover} src={image} alt="" width="1200" height="630"/>
                {description}
            </section>
            <footer className={styles.footer}>
                <div className={styles.tags}>
                    {tags.map((tag) => <a key={tag} className={styles.tag} href={`/tags/${tag}`}>{tag}</a>)}
                </div>
            </footer>
        </article>
    );
}

export default PostCard;
