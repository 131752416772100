import * as firebase from 'firebase/app';

const config = {
    apiKey: "AIzaSyBWrXKZ-8BUAvDlGxgi5qtziJeO2EPtkMs",
    authDomain: "hypevizz.firebaseapp.com",
    databaseURL: "https://hypevizz.firebaseio.com",
    projectId: "hypevizz",
    storageBucket: "hypevizz.appspot.com",
    messagingSenderId: "39056203633",
    appId: "1:39056203633:web:d4431755ce8b2e762620d0"
};

export default firebase.initializeApp(config);
