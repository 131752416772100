import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Home from 'pages/home/Home';
import About from 'pages/about/About';
import ComingSoon from 'pages/error/ComingSoon';
import NotFound from 'pages/error/NotFound';

function App() {
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route path="/tags" component={ComingSoon}/>
                <Route path="/authors" component={ComingSoon}/>
                <Route path="/about" component={About}/>
                <Route component={NotFound}/>
            </Switch>
        </Router>
    );
}

export default App;
